import { get, put, del, post, getZip } from "./base";

export function getTableCard(conventionId,name,pageNo,pageSize){
      return get(`/conferenceAffairs/material/generate/tableCard?conventionId=${conventionId}&name=${name}&pageNo=${pageNo}&pageSize=${pageSize}`)
}

export function postTableCard(conventionId,inputUrl,code){
         return post(`/conferenceAffairs/material/generate/tableCard?conventionId=${conventionId}&inputUrl=${inputUrl}&code=${code}`)
}
export function putTableCard(data){
       return put('/conferenceAffairs/expert/updInfo/tableCard',data)
}
export function getTableCardNum(conventionId){
      return get(`/conferenceAffairs/material/tableCard/name/${conventionId}`)
}
export function getTableCardNumList(conventionId){
      return get(`/conferenceAffairs/material/tableCard/name/list/${conventionId}`)
}
export function putNewCard(id,inputUrl,code){
        return put(`/conferenceAffairs/material/generate/tableCard?id=${id}&inputUrl=${inputUrl}&code=${code}`)
}
export function getCardType(type){
        return get(`/conferenceAffairs/material/selectMaterialLayoutTemplate?type=${type}`)
}
export function getImgList(code,data) {
        return getZip(`/conferenceAffairs/material/downloadAllTable/${code}`,data);
}