<template>
  <!-- 新增/修改 -->
  <a-drawer
      :title="addTitle"
      :width="860"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose()"
  >
    <a-form-model
        ref="addItem"
        :label-col="labelCol"
        :model="addForm"
        :rules="addRules"
        :wrapper-col="wrapperCol"  >

      <a-row :gutter="24">
        <a-col
            :span="12"
          >
          <a-form-model-item label="头像(正方形)" prop="headImg">
            <div>
              <div style="display: flex">
                <div class="uploadImg">
                  <input
                      id="inputClear"
                      ref="addImg"
                      accept="image/*"
                      style="width: 100%;height:100%;opacity: 0;"
                      type="file"
                      @change="uploadImg('addImg')"/>
                </div>
                <div class="upload_gutter">
                  <img
                      v-if="addForm.headImg"
                      :src="addForm.headImg"
                      alt=""
                      style="width: 120px;height: 120px"
                  />
                </div>
              </div>
              <span style="font-size: xx-small">注：尺寸100*100，大小不超过500kb</span>
            </div>
          </a-form-model-item>
        </a-col>
        <a-col
            :span="12"
          >
          <a-form-model-item label="头像(长方形)">
             <div>
               <div style="display: flex">
                 <div class="upload2Img">
                   <input
                       id="inputClear2"
                       ref="addImgRect"
                       accept="image/*"
                       style="width: 100%;height:100%;opacity: 0;"
                       type="file"
                       @change="uploadImg('addImgRect')"/>
                 </div>
                 <div class="upload_gutter2">
                   <img
                       v-if="addForm.headImgRect"
                       :src="addForm.headImgRect"
                       alt=""
                       style="width: 100%;height:100%"/>
                 </div>
               </div>
               <span style="font-size: xx-small">注：尺寸100*100，大小不超过500kb</span>
             </div>
           </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col
            :span="12"
          >
          <a-form-model-item label="姓名" prop="name">
            <a-input v-model="addForm.name" placeholder="请输入" />
          </a-form-model-item>
        </a-col>

        <a-col
            :span="12"
          >
          <a-form-model-item label="性别" prop="sex">
            <a-checkbox-group name="checkboxGroup" v-model="addForm.sex" :default-value="['1']" @change="onChangeOption" >
              <a-checkbox :value="1" optionType="button">
                男
              </a-checkbox>
              <a-checkbox :value="2">
                女
              </a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col
            :span="12"
          >
          <a-form-model-item label="身份证号" prop="idNumber">
            <a-input v-model="addForm.idNumber" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col
            :span="12"
          >
          <a-form-model-item label="出生日期" prop="birthDate">
            <a-date-picker
              v-model="addForm.birthDate"
              style="width: 100%"
              placeholder="请选择"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col
            :span="12"
          >
          <a-form-model-item label="所在地区" prop="province">
            <a-cascader
                v-model="addForm.province"
                :options="hospitaloPtions"
                placeholder="请选择"
            />
          </a-form-model-item>
        </a-col>
        <a-col
            :span="12"
          >
          <a-form-model-item label="工作单位" prop="hospital">
            <a-input v-model="addForm.hospital" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col
            :span="12"
          >
          <a-form-model-item label="所在部门" prop="department">
            <a-input v-model="addForm.department" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col
            :span="12"
          >
          <a-form-model-item label="职称/职位"  prop="job">
            <a-input v-model="addForm.job" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col
            :span="12"
          >
          <a-form-model-item label="手机号" prop="phone">
            <a-input v-model="addForm.phone" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col
            :span="12"
          >
          <a-form-model-item label="电子邮箱" prop="email">
            <a-input v-model="addForm.email" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col
            :span="12"
          >
          <a-form-model-item label="开户行" prop="openingBank">
            <a-input v-model="addForm.openingBank" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col
            :span="12"
          >
          <a-form-model-item label="银行卡号" prop="bankCardNumber">
            <a-input v-model="addForm.bankCardNumber" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="24" class="row">
        <a-col
            :span="24"
          >
          <a-form-model-item label="简介" v-if="addTitle === '新建'" prop="into">
            <a-textarea auto-size
                    placeholder="请输入"
                    v-model="addForm.into"
                    style="min-height: 400px;width:900px"/>
          </a-form-model-item>
        </a-col>
        <!-- <a-col
            :span="12"
          >
          <a-form-model-item label="简介" v-if="addTitle === '新建'">
            <a-textarea auto-size
                    placeholder="请输入"
                    v-model="addForm.into"
                    style="min-height: 400px;"/>
          </a-form-model-item>
        </a-col> -->
      </a-row>
    </a-form-model>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="addClose">
        取消
      </a-button>

      <!--新增  -->
      <a-button type="primary" @click="addOk">
        确认
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import City from "@/assets/city.json";
import {update} from "@/utils/update";
import {getAddInfo, postAddInfo, putAddInfo,putAddxiongKaInfo} from "@/service/participants";
import {putTableCard} from "@/service/zhuoCard_api.js"

import {dutyParagraphIsRepeat} from "@/service/authentication";
export default {
name: "ExpertView",
  props:{
    addTitle:{
      type:String
    },
    visible:{
      type:Boolean,
      default:false,
    },
    // addForm:{
    //   type:Object
    // },
    expertId:{
      type:Number
    },
    //来自那个页面
    formPage:{
      type:String
    },
    zhuoCardId:{
      type:String
    }

    
  },
  data(){
    let validateMobile = async (rule, value, callback) => {
      if (!value) {
        return callback(); //取消报错
      } else {
        if (!/^1[0-9]{10}$/.test(value)) { //格式错误
          return callback(new Error('手机号码格式不正确'));
        } else { //格式正确
          return callback(); //取消报错
        }
      }
    };
  return{
    labelCol: {span: 6},
    wrapperCol: {span: 18},
    addForm:{sex:[]},
    addRules: {
      name: [{ required: true,message: '请输入', trigger: 'blur'}],
      sex: [{ required: true,message: '请选择', trigger: 'change'}],
      // phone: [{ required: true,validator: validateMobile, trigger: 'blur'}],
      phone: [{ required: true,message: '请输入11位手机号', trigger: 'blur',pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,}],
      province: [{ required: true, message: '请选择', trigger: 'change' }],
      enclosure: [{required: true, message: '请上传', trigger: 'blur'}],
      headImg: [{required: true, message: '请上传', trigger: 'change'}],
      hospital: [{ required: true, message: '请输入', trigger: 'change' }],
      // department: [{required: true, message: '请输入', trigger: 'change'}],
      into: [{required: true, message: '请输入', trigger: 'blur'}],
      // job: [{required: true, message: '请输入', trigger: 'change'}],
    },
    City:City,
    hospitaloPtions:[],
    checkBoxObj: {
      tempCheckedList:[1],
      checkedList: [1]
    },

  }
  },
  created() {
    this.getPCH();
    if(this.expertId){
      //编辑时才进入
      this.expertInfor();
    }
  },
  methods:{
    onChangeOption(checkedList) {
      if (checkedList.length < 1) { // 实现必须有一个选中的效果
          this.addForm.sex = this.checkBoxObj.tempCheckedList; // 点击选中项不能取消选中
          return;
      }
      // 删太早了
      if(checkedList.length>1){
        checkedList = checkedList.slice(1); // 实现单选效果
        this.checkBoxObj.tempCheckedList = checkedList; // 缓存当前选中项，在取消选中时使用
        this.addForm.sex = checkedList; // 更新选中
      }
    },

    //根据专家id，获取专家信息
    async expertInfor() {
      let data = {
        id:this.expertId
      }
      const response = await getAddInfo(data)
      if (response.code === 200) {
        let row = response.data[0];
        this.addForm = {
          id:row.id,
          headImg:row.headimg,
          headImgRect:row.headimgRect,
          name:row.name,
          province:[row.province,row.city],
          hospital:row.hospital,
          phone:row.phone,
          department:row.hospitalOffice,
          job:row.title,
          email:row.email,
          openingBank:row.openingBank,
          bankCardNumber:row.bankCardNumber,
          into:row.intro,
          idNumber:row.idNumber,
          birthDate:row.birthDate,
          sex:[row.sex]
        }
      }
    },
    addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          if (typeof this.addForm.birthDate == "object" && this.addForm.birthDate!== null) {
            // var hhh;
            this.addForm.birthDate = this.addForm.birthDate.format("YYYY-MM-DD");
            // this.addForm.birthDate = hhh;
          }
          const form = this.addForm;
          let data;
          if(this.addTitle === '新建'){ //新增
            data = {
              "name": form.name, //姓名
              "headimg": form.headImg, //头像1
              "headimgRect": form.headImgRect, //头像2
              "province": form.province[0], //省份
              "city": form.province[1], //城市
              "hospital": form.hospital, //医院
              "hospitalOffice": form.department, //科室
              "title": form.job, //职称
              "phone": form.phone, //手机号
              "intro": form.into, //简介
              "email": form.email, //邮箱
              "sex": form.sex[0], //性别
              "idNumber": form.idNumber, //身份证号
              "birthDate": form.birthDate, //生日
              "openingBank": form.openingBank, //开户行
              "bankCardNumber": form.bankCardNumber, //银行卡号
              // "tags": this.inputValue, //标签
            }
            this.postAddInfoList(data);
          }else if(this.addTitle === '编辑'){
            data = {
              "id":form.id,
              "name": form.name, //姓名
              "headimg": form.headImg, //头像1
              "headimgRect": form.headImgRect, //头像2
              "province": form.province[0], //省份
              "city": form.province[1], //城市
              "hospital": form.hospital, //医院
              "hospitalOffice": form.department, //科室
              "title": form.job, //职称
              "phone": form.phone, //手机号
              // "intro": form.into, //简介
              // "tags": this.inputValue, //标签
              "email": form.email, //邮箱
              "sex": form.sex[0], //性别
              "idNumber": form.idNumber, //身份证号
              "birthDate": form.birthDate, //生日
              "openingBank": form.openingBank, //开户行
              "bankCardNumber": form.bankCardNumber, //银行卡号
            }
            if(this.formPage=='zhuoCard'){
               this.putZhuoCard(data)
            }else if(this.formPage=='ElectronicMaterial'){
              this.putXiongCard(data)
            }
            else{
               this.putAddInfoList(data);
            }
           
          }

        }
      })
    },
    //新增vip
    async postAddInfoList(data) {
      const response = await postAddInfo(data)
      if(response.code === 200){
        this.$message.success("新增成功！");
        // await  this.getLogData(); //刷新页面
        if(this.formPage === 'SpecialistManage'){
          console.log('刷新了')
          this.$parent.getLogData();
        }else if(this.formPage === 'Participants'){
          console.log('刷新了2')
          this.$parent.getConfereeSelect();
        }

        this.addClose();
      }
    },

    //修改
    async putAddInfoList(data) {
      const response = await putAddInfo(data)
      if(response.code === 200){
        this.$message.success("修改成功！");
        // await  this.getLogData(); //刷新页面
        if(this.formPage === 'SpecialistManage'){
          console.log('刷新了')
          this.$parent.getLogData();
        }else if(this.formPage === 'Participants'){
          console.log('刷新了2')
          this.$parent.getConfereeSelect();
        }
        this.addClose();
      }
    },
    async putZhuoCard(data){
       let data1={
        id:this.zhuoCardId,
        expert:data
       }
       const response= await putTableCard(data1)
         if(response.code === 200){
        this.$message.success("修改成功！");
         
         this.$parent.$parent.getCardList();
         this.$parent.$parent.getNum();
         this.addClose();
         }
       
    },
    async putXiongCard(data){
       let data1={
        id:this.zhuoCardId,
        expert:data
       }
       const response= await putAddxiongKaInfo(data1)
         if(response.code === 200){
           this.$message.success("修改成功！");
           this.$parent.$parent.getCard();
           this.$parent.$parent.ViewTotal();
           this.addClose();
         }
       
    },
    //上传图片
    async uploadImg(type) {
      var inputClear = document.querySelector('#inputClear')
      var inputClear2 = document.querySelector('#inputClear2')
      // var inputClear3 = document.querySelector('#inputClear3')
      // var inputClear4 = document.querySelector('#inputClear4')
      let inputDOM = this.$refs[type].files[0];
      if(inputDOM.size <512000){
        const image =await update(inputDOM)
        if (image.code === 0){
          switch (type){
            case 'addImg': //头像1
              this.$set(this.addForm,'headImg',image.data.url)
              break;
            case 'addImgRect': //头像2
              this.$set(this.addForm,'headImgRect',image.data.url)
              break;
            // case 'editImg': //修改 - 头像1
            //   this.$set(this.editForm,'headImg',image.data.url)
            //   break;
            // case 'editImgRect': //修改 - 头像2
            //   this.$set(this.editForm,'headImgRect',image.data.url)
            //   break;
          }
          this.$message.success('上传成功')
          if (inputClear.value !== '') {
            inputClear.value = ''
          } else if (inputClear2.value !== '') {
            inputClear2.value = ''
          }
      // else if (inputClear3.value !== '') {
      //       inputClear3.value = ''
      //     } else if (inputClear4.value !=='') {
      //       inputClear4.value =''
      //     }
        }
        else {
          this.$message.warning('上传失败'+ image.message)
        }
      }
      else {
        this.$message.warning('仅上传500k内图片')
      }
    },
    addClose(){
      let flag = false;
      this.$emit("closeMain", flag); //子组件向父组件传值（开关）
    },
    getPCH(){
      let province = []
      this.City.forEach((item,index)=>{
        let city = []
        province.push({
          value:item.name,
          label:item.name,
          children:city
        })
        item.cityList.forEach((list)=>{
          city.push({
            value:list.name,
            label:list.name,
            loading:false,
          })
        })
        province[index].children = city
      })
      this.hospitaloPtions = province
    },
  }
}
</script>

<style scoped>
.uploadImg {
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.upload2Img {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 120px;
  background-image: url("../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.upload_gutter {
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  margin-left: 10px;
}

.upload_gutter2 {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 120px;
  margin-left: 10px;
}
.row ::v-deep .ant-col-6 {
  width:12%
}
.row ::v-deep .ant-col-18 {
  width:88%
}

</style>
