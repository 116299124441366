import {get, post, put, del, getExel, postById, putById, getById, postWorld, postExel} from './base'


/**
 * 获取参会人员
 * @returns {Promise<* | void>}
 * @param conventionCode
 */
export function confereeSelectAll(conventionCode,data){
    return get("/conferenceAffairs/convention/member/selectAll/"+conventionCode,data)
}
/**
 * 获取参会人员 -- 新版
 * @returns {Promise<* | void>}
 * @param conventionCode
 */
export function confereeSelectAllNew(conventionCode,data){
    return get("/conferenceAffairs/convention/member/selectAll/"+conventionCode,data)
}
/**
 * 获取参会人员 --根据id单条数据-- 新版
 * @returns {Promise<* | void>}
 * @param conventionCode
 */
export function confereeSelectAllNewOne(conventionCode,data){
    return get("/conferenceAffairs/convention/member/selectAll/"+conventionCode,data)
}
/**
 * 获取参会人员各个状态数量 -- 新版
 * @returns {Promise<* | void>}
 * @param conventionCode
 */
export function selectConventionMemberStatusNumber(conventionCode){
    return get("/conferenceAffairs/convention/member/selectConventionMemberStatusNumber/"+conventionCode)
}

/**
 * 修改参会人员信息 -- 新版
 * @returns {Promise<* | void>}
 * @param conventionCode
 */
export function updInfo(data){
    return putById("/conferenceAffairs/convention/member/updInfo",data)
}
/**
 * 获取本次会议住宿酒店信息 -- 新版
 * @returns {Promise<* | void>}
 * @param conventionCode
 */
export function selectHotel(conventionCode){
    return getById("/conferenceAffairs/convention/member/selectHotel/"+conventionCode)
}

/**
 * 会议日程导入 -- 新版
 * @returns {Promise<* | void>}
 * @param conventionCode
 */
export function scheduleImportRcExcel(conventionCode,file){
    return postById("/conferenceAffairs/schedule/importRcExcel?conventionCode="+conventionCode,file)
}

/**
 * 会议日程导入 -- 新版
 * @returns {Promise<* | void>}
 * @param memberType
 * @param data
 */
export function getMemberList(memberType,data){
    return get("/conferenceAffairs/task/member/"+memberType,data)
}
// export function getNewMemberList(memberType,data){
//     return get("/conferenceAffairs/task/member/"+memberType,data)
// }

export function getNewMemberList(conventionCode,data) {
  return get(`/conferenceAffairs/task/member/fzr/${conventionCode}`,data);
}

/**
 * 新增参会人员管理
 * @returns {Promise<* | void>}
 * @param data
 */
export function confereeAddInfo(data){
    return postById("/conferenceAffairs/convention/member/addInfo",data)
}


/**
 * 修改参会人员信息
 * @returns {Promise<* | void>}
 * @param data
 */
export function confereeUpInfo(data){
    return putById("/conferenceAffairs/convention/member/updInfo",data)
}


/**
 * 删除参会人员
 * @returns {Promise<* | void>}
 * @param data
 */
export function confereeDelInfo(data){
    return putById("/conferenceAffairs/convention/member/delInfo",data)
}

/**
 * 批量删除参会人员
 * @returns {Promise<* | void>}
 * @param data
 */
export function confereeDelInfoBatches(data){
    return putById("/conferenceAffairs/convention/member/delInfoIdList",data)
}


//获取签名
export function get_signatureCrew(){
    return post("/api/admin/getUgcUploadSign")
}




/**
 * 获取参会人员讲课内容
 * @returns {Promise<* | void>}
 * @param conventionMemberId
 */
export function confereeTeachSelectAll(conventionMemberId,data){
    return get("/conferenceAffairs/convention/member/lecture/selectAll?conventionMemberId="+conventionMemberId,data)
}

/**
 * 添加参会人员讲课内容
 * @returns {Promise<* | void>}
 * @param data
 */
export function confereeTeachAddInfo(data){
    return postById("/conferenceAffairs/convention/member/lecture/addInfo",data)
}


/**
 * 修改参会人员讲课内容
 * @returns {Promise<* | void>}
 * @param data
 */
export function confereeTeachUpInfo(data){
    return putById("/conferenceAffairs/convention/member/lecture/updInfo",data)
}


/**
 * 删除参会人员讲课内容
 * @returns {Promise<* | void>}
 * @param data
 */
export function confereeTeachDelInfo(data){
    return putById("/conferenceAffairs/convention/member/lecture/delInfo",data)
}



/**
 * 获取科室列表
 * @returns {Promise<* | void>}
 * @param data
 */
export function selectOfficesl(){
    return getById("/conferenceAffairs/expert/selectOffices")
}


/**
 * 获取VIP列表
 * @returns {Promise<* | void>}
 * @param data
 */
export function getAddInfo(data){
    return getById("/conferenceAffairs/expert/selectAll",data)
}

/**
 * 新增VIP
 * @returns {Promise<* | void>}
 * @param data
 */
export function postAddInfo(data){
    return postById("/conferenceAffairs/expert/addInfo",data)
}

/**
 * 更新VIP
 * @returns {Promise<* | void>}
 * @param data
 */
export function putAddInfo(data){
    return putById("/conferenceAffairs/expert/updInfo",data)
}

export function putAddxiongKaInfo(data){
    return putById("/conferenceAffairs/expert/updInfo/badge",data)
}

/**
 * 删除VIP
 * @returns {Promise<* | void>}
 * @param data
 */
export function delAddInfo(data){
    return putById("/conferenceAffairs/expert/delInfo",data)
}





/**
 * 获取附件列表
 * @returns {Promise<* | void>}
 * @param expertId
 */
export function selectEnclosureAll(expertId){
    return get("/conferenceAffairs/expert/selectEnclosureAll/"+expertId)
}

/**
 * 获取附件列表
 * @returns {Promise<* | void>}
 * @param data
 */
export function enclosureInsert(data){
    return postById("/conferenceAffairs/expert/enclosureInsert",data)
}

/**
 * 删除附件
 * @returns {Promise<* | void>}
 * @param data
 */
export function delEnclosureInsert(data){
    return putById("/conferenceAffairs/expert/enclosureDelete",data)
}


/**
 * 导出参会人员    导出全部/批量导出（{ "idList": [1, 2, 3, 4, 5] }）
 * @returns {Promise<* | void>}
 * @param code
 */
export function exportMember(code,data){
    return postExel("/conferenceAffairs/convention/member/exportMember/"+code,data)
}


/**
 * 获取附件列表
 * @returns {Promise<* | void>}
 * @param code
 */
export function exportMb(data){
    return getExel("/conferenceAffairs/schedule/exportMb",data)
}

// 导入参会vip
export function postImportMember(data){
  return postById("/conferenceAffairs/expert/import",data)
}
// 下载模板
export function exportTemplate(data){
  return getExel("/conferenceAffairs/expert/export/template",data)
}
